<template>
  <div :id="idName" @click="generate">
    <slot>Download {{ name }}</slot>
  </div>
</template>

<script>
import mapKeys from "lodash.mapkeys";
import pickBy from "lodash.pickby";
import pick from "lodash.pick";
import { saveAs } from "file-saver";
import { unparse } from "papaparse";
import * as moment from "moment";
import { planPeriodicDescription } from "@/helpers/variables/translateString";
import { mapGetters } from "vuex";

export const isType = (value, type) => typeof value === type;
export default {
  name: "JsonCSV",
  props: {
    data: { type: Array },
    fields: { type: Array, required: false },
    primaryName: { type: String, default: "" },
    month: { type: String, default: "" },
    delimiter: { type: String, default: ",", required: false },
    fileName: { type: String, default: "" },
    /**
     * Should the module add SEP={delimiter}
     *
     * Useful for opening file with Excel
     */
    separatorExcel: { type: Boolean, default: false },
    encoding: { type: String, default: "utf-8" },
    labels: { required: false },
    dataRequester: { type: Function },
  },

  data() {
    return {
      requestResult: null,
    };
  },

  beforeMount() {
    this.date;
  },

  computed: {
    idName() {
      const now = new Date().getTime();
      return "export_" + now;
    },

    ...mapGetters(["usersCustomTagKeys", "usersCustomTags"]),

    exportableData() {
      const filteredData = this.cleaningData();
      if (!filteredData.length) {
        return null;
      }
      return filteredData;
    },

    name() {
      if (this.fileName) {
        return `${this.fileName}.csv`;
      } else {
        let company = this.primaryName.replace(/ /g, "_");
        let month = this.date.replace(/ /g, "_");
        return `${company}_${month}.csv`;
      }
    },

    date() {
      if (this.month == "") {
        return moment().format("DD-MM-YYYY");
      } else {
        return this.month;
      }
    },
    dataOrResult() {
      return this.data || this.requestResult;
    },
  },

  methods: {
    labelsFunctionGenerator() {
      if (
        !isType(this.labels, "undefined") &&
        !isType(this.labels, "function") &&
        !isType(this.labels, "object")
      ) {
        throw new Error("Labels needs to be a function(value,key) or object.");
      }
      if (isType(this.labels, "function")) {
        return (item) => {
          let _mapKeys = mapKeys(item, this.labels);
          return _mapKeys;
        };
      }
      if (isType(this.labels, "object")) {
        return (item) => {
          return mapKeys(item, (item, key) => {
            return this.labels[key] || key;
          });
        };
      }
      return (item) => item;
    },
    fieldsFunctionGenerator() {
      if (
        !isType(this.fields, "undefined") &&
        !isType(this.fields, "function") &&
        !isType(this.fields, "object") &&
        !Array.isArray(this.fields)
      ) {
        throw new Error("Fields needs to be a function(value,key) or array.");
      }
      if (
        isType(this.fields, "function") ||
        (isType(this.fields, "object") && !Array.isArray(this.fields))
      ) {
        return (item) => {
          return pickBy(item, this.fields);
        };
      }
      if (Array.isArray(this.fields)) {
        return (item) => {
          return pick(item, this.fields);
        };
      }
      return (item) => item;
    },

    getCustomTagsData(customTags = {}) {
      return this.usersCustomTagKeys.reduce((acc, tagKey) => {
        const customTag = customTags[tagKey] || this.usersCustomTags[tagKey];
        const customTagName = customTag.name;
        const customTagValue = customTag.value;
        acc[customTagName] = customTagValue;
        return acc;
      }, {});
    },

    cleaningData() {
      if (
        isType(this.fields, "undefined") &&
        isType(this.labels, "undefined")
      ) {
        return this.dataOrResult;
      }
      const labels = this.labelsFunctionGenerator();
      const fields = this.fieldsFunctionGenerator();

      return this.dataOrResult.map((item) => {
        let newData = labels(fields(item));
        if ("Periodicidade" in newData) {
          newData["Periodicidade"] =
            planPeriodicDescription[newData["Periodicidade"]];
        }

        if ("custom_tags" in newData) {
          const customTagsData = this.getCustomTagsData(newData.custom_tags);
          delete newData["custom_tags"];
          newData = { ...newData, ...customTagsData };
        }

        return newData;
      });
    },
    async generate() {
      this.$emit("export-started");
      if (this.dataRequester) {
        this.requestResult = await this.dataRequester();
      }

      let dataExport = this.exportableData;

      if (!dataExport) {
        console.error("No data to export");
        return;
      } else {
        this.generateCSV(dataExport);
      }
    },
    generateCSV(data) {
      let csv = unparse(
        data,
        Object.assign(
          {
            delimiter: this.delimiter,
            encoding: this.encoding,
          },
          this.advancedOptions
        )
      );
      if (this.separatorExcel) {
        csv = "SEP=" + this.delimiter + "\r\n" + csv;
      }
      if (this.encoding === "utf-8") {
        csv = "\ufeff" + csv;
      }
      this.$emit("export-finished");
      if (!this.testing) {
        let blob = new Blob([csv], {
          type: "application/csvcharset=" + this.encoding,
        });
        saveAs(blob, this.name);
      }
    },
  },
};
</script>

<style scoped>
div {
  display: inline;
}
</style>
